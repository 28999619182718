<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>网站管理</el-breadcrumb-item>
      <el-breadcrumb-item>栏目标题</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 分类列表 -->
    <el-card>
      <el-table :data="showMaplist"
                style="width: 100%;margin-bottom: 20px;"
                row-key="id"
                stripe
                default-expand-all
                :tree-props="{children: 'children'}">
        <el-table-column type="index"
                         label="#"></el-table-column>
        <el-table-column prop="name"
                         label="栏目名称"></el-table-column>
        <el-table-column prop="ename"
                         label="栏目别名"></el-table-column>

        <el-table-column prop="level"
                         label="等级">
          <template slot-scope="scope">
            <el-tag size="mini"
                    v-if="scope.row.level === 0">一级</el-tag>
            <el-tag type="success"
                    size="mini"
                    v-else-if="scope.row.level === 1">二级</el-tag>
            <el-tag type="wraning"
                    size="mini"
                    v-else>三级</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status"
                         label="是否显示">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"
                       :active-value="1"
                       :inactive-value="0"
                       @change="stateChange(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         width="200">
          <template slot-scope="scope">
            <el-button type="primary"
                       icon="el-icon-edit"
                       size="mini"
                       @click="showEditDialog(scope.row.id)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 编辑表单 -->
    <el-dialog title="修改栏目"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               width="50%"
               @close="addDialogClose">
      <!-- 表单区域 -->
      <el-form :model="dateForm"
               ref="dateFormRef"
               label-position="top">
        <el-form-item label="栏目名称：">
          <el-input v-model="dateForm.name"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="栏目别名：">
          <el-input v-model="dateForm.ename"></el-input>
        </el-form-item>
        <el-form-item label="栏目简述：">
          <el-input type="textarea"
                    :rows="3"
                    resize="none"
                    v-model="dateForm.content"></el-input>
        </el-form-item>

        <el-form-item label="顶部图片：">
          <el-upload :data="imgtype"
                     :action="uploadURL"
                     :headers="headerObj"
                     :limit="1"
                     :on-preview="handlePreview"
                     :before-upload="beforeUpload"
                     :on-success="handleSuccess"
                     :file-list="fileList"
                     list-type="picture">
            <el-button size="small"
                       type="primary">点击上传</el-button>
            <div slot="tip"
                 class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
          <el-dialog :visible.sync="imgDialogVisible"
                     width="50%"
                     append-to-body>
            <img width="100%"
                 :src="dialogImageUrl"
                 alt="">
          </el-dialog>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="upDate">确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
export default {
  data () {
    return {
      showMaplist: [],
      dateForm: {
        name: '',
        ename: '',
        content: '',
        logo: ''
      },
      dialogVisible: false,
      // 图片上传地址
      uploadURL: this.globalUrlapi + 'logo',
      headerObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      imgtype: {
        type: 'map'
      },
      fileList: [],
      imgDialogVisible: false,
      dialogImageUrl: ''
    }
  },
  methods: {
    async getMapList () {
      const { data: res } = await this.$http.get('map')
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.showMaplist = res.data
    },

    // 展示修改对话框
    async showEditDialog (id) {
      const { data: res } = await this.$http.get('map/' + id)
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.dateForm = res.data
      this.fileList.push({ name: res.data.logo, url: this.globalUrl + res.data.logo })
      this.dialogVisible = true
    },

    // 关闭 Dialog，清除历史数据
    addDialogClose () {
      this.$refs.dateFormRef.resetFields()
      this.fileList = []
    },

    // 查看图片
    handlePreview (fileList) {
      this.dialogImageUrl = fileList.url
      this.imgDialogVisible = true
    },

    // 图片上传前校验
    beforeUpload (file) {
      const isIMAGE = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt1M = file.size / 1024 / 500 < 1
      if (!isIMAGE) {
        this.$message.error('上传文件只能是 jpg 或 png 图片格式!')
      }
      if (!isLt1M) {
        this.$message.error('上传文件大小不能超过 500kb!')
      }
      return isIMAGE && isLt1M
    },

    // 图片上传成功回调
    handleSuccess (response) {
      this.dateForm.logo = response.data
    },

    async upDate () {
      const { data: res } = await this.$http.put('map/' + this.dateForm.id, {
        name: this.dateForm.name,
        ename: this.dateForm.ename,
        content: this.dateForm.content,
        logo: this.dateForm.logo
      })
      if (res.code !== 200) {
        return this.$message.error('修改管理员信息失败，请重试。')
      }
      this.$message.success('修改成功！')
      // 隐藏对话框
      this.dialogVisible = false
      this.getMapList()
    }
  },

  created () {
    this.getMapList()
  }
}
</script>

<style>
</style>
