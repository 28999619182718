<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>网站管理</el-breadcrumb-item>
      <el-breadcrumb-item>网站设置</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 视图区 -->
    <el-card>
      <el-form :model="editForm"
               :rules="editFormRules"
               ref="editFormRef"
               label-position="top">
        <el-form-item label="网站标题："
                      prop="web_title">
          <el-input v-model="editForm.web_title"></el-input>
        </el-form-item>
        <el-form-item label="关键字："
                      prop="web_keywords">
          <el-input v-model="editForm.web_keywords"></el-input>
        </el-form-item>
        <el-form-item label="网站描述："
                      prop="web_description">
          <el-input type="textarea"
                    v-model="editForm.web_description"></el-input>
        </el-form-item>
        <!-- 网站 logo 上传 -->
        <el-form-item label="网站 Logo 上传："
                      prop="web_logo">
          <el-upload :data="imgtype"
                     :action="uploadURL"
                     :headers="headerObj"
                     :limit="1"
                     :on-preview="handlePreview"
                     :before-upload="beforeUpload"
                     :on-success="handleSuccess"
                     :file-list="fileList"
                     list-type="picture">
            <el-button size="small"
                       type="primary">点击上传</el-button>
            <div slot="tip"
                 class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </el-form-item>

        <el-form-item label="公司电话："
                      prop="web_phone">
          <el-input v-model="editForm.web_phone"></el-input>
        </el-form-item>
        <el-form-item label="联系手机号：">
          <el-input v-model="editForm.web_dh"></el-input>
        </el-form-item>
        <el-form-item label="联系邮箱："
                      prop="web_mail">
          <el-input v-model="editForm.web_mail"></el-input>
        </el-form-item>
        <el-form-item label="联系地址："
                      prop="web_add">
          <el-input v-model="editForm.web_add"></el-input>
        </el-form-item>
        <el-form-item label="版权声明："
                      prop="web_copyright">
          <el-input v-model="editForm.web_copyright"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="upDate">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {

      // 网站设置表单
      editForm: {
        web_title: '',
        web_keywords: '',
        web_description: '',
        web_phone: '',
        web_dh: '',
        web_mail: '',
        web_add: '',
        web_copyright: '',
        web_logo: '',
        web_pic: ''
      },

      // 表单校验
      editFormRules: {
        web_title: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ],
        web_keywords: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ],
        web_description: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ],
        web_logo: [
          { required: true, message: '请选择上传图片', trigger: 'change' }
        ],
        web_phone: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ],
        web_mail: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ],
        web_add: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ],
        web_copyright: [
          { required: true, message: '请请输入内容', trigger: 'blur' }
        ]
      },

      // 图片上传地址
      uploadURL: this.globalUrlapi + '/logo',
      headerObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      imgtype: {
        type: 'setting'
      },
      fileList: [],
      imgDialogVisible: false,
      dialogImageUrl: ''
    }
  },

  methods: {
    // 获取页面数据
    async getSetDate () {
      const { data: res } = await this.$http.get('setting')
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.editForm = res.data
      this.fileList.push({ name: res.data.web_logo, url: this.globalUrlapi + res.data.web_logo })
    },

    // 查看图片
    handlePreview (fileList) {
      this.dialogImageUrl = fileList.url
      this.imgDialogVisible = true
    },

    // 图片上传前校验
    beforeUpload (file) {
      const isIMAGE = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt1M = file.size / 1024 / 500 < 1
      if (!isIMAGE) {
        this.$message.error('上传文件只能是 jpg 或 png 图片格式!')
      }
      if (!isLt1M) {
        this.$message.error('上传文件大小不能超过 500kb!')
      }
      return isIMAGE && isLt1M
    },

    // 图片上传成功回调
    handleSuccess (response) {
      this.editForm.web_logo = response.data
    },

    // 发起上传请求
    upDate () {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('setting', this.editForm)
        if (res.code !== 200) {
          this.$message.error('网络延时，请重新刷新网页重试...')
        }
        this.$message.success('修改成功！')
      })
    }
  },

  created () {
    this.getSetDate()
  }

}
</script>
