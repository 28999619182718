<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>消息管理</el-breadcrumb-item>
      <el-breadcrumb-item>询盘咨询</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 视图区 -->
    <el-card>
      <!-- 视图列表区 -->
      <el-table :data="messageList"
                stripe
                style="width: 100%">
        <el-table-column type="index"
                         label="#"
                         width="100"></el-table-column>
        <el-table-column prop="type"
                         label="询盘类型">
          <template slot-scope="scope">
            <div v-if="scope.row.type === 0">产品咨询</div>
            <div v-else-if="scope.row.type === 1">售后申请</div>
            <div v-else-if="scope.row.type === 2">彩页资料申请</div>
            <div v-else>其它</div>
          </template>
        </el-table-column>
        <el-table-column prop="level"
                         label="状态">
          <template slot-scope="scope">
            <el-tag type="warning"
                    v-if="scope.row.status === 0">未读</el-tag>
            <el-tag type="info"
                    v-else>已读</el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="update_time"
                         label="留言时间"></el-table-column>
        <el-table-column label="操作"
                         width="200">
          <template slot-scope="scope">
            <el-button type="primary"
                       icon="el-icon-view"
                       size="mini"
                       @click="showMessageDialog(scope.row.id)">查看</el-button>
            <el-button type="warning"
                       icon="el-icon-delete"
                       size="mini"
                       @click="deleteById(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 查看留言详情 -->
    <el-dialog title="询盘详情"
               :visible.sync="messageDialogVisible"
               ref="messageDialogBox"
               width="70%"
               @close="getMessageList">

      <el-descriptions class="margin-top"
                       :column="2"
                       border>
        <el-descriptions-item>
          <template slot="label">姓</template>
          {{ messageCurrentDate.firstName }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">名字</template>
          {{ messageCurrentDate.lastName }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">Email</template>
          {{ messageCurrentDate.email }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">联系电话</template>
          {{ messageCurrentDate.phone }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">国家地区</template>
          {{ messageCurrentDate.country }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">公司名称</template>
          {{ messageCurrentDate.company }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">职位称呼</template>
          {{ messageCurrentDate.job }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">发送时间</template>
          <el-tag size="small">{{ messageCurrentDate.update_time }}</el-tag>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">咨询产品</template>
          <el-tag size="small">{{ messageCurrentDate.pid }}</el-tag>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">联系地址</template>
          {{ messageCurrentDate.dizi }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">留言备注</template>
          {{ messageCurrentDate.message }}
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      messageDialogVisible: false,
      messageList: [],
      messageCurrentDate: {
        email: ''
      }
    }
  },

  created () {
    this.getMessageList()
  },

  methods: {
    async getMessageList () {
      const { data: res } = await this.$http.get('online')
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.messageList = res.data
    },

    async showMessageDialog (id) {
      const { data: res } = await this.$http.get('online/' + id)
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.messageCurrentDate = res.data
      // this.$refs.messageDialogBox.$el.firstChild.style.height = '600px'
      this.messageDialogVisible = true
    },

    // 删除该ID信息
    async deleteById (id) {
      const confirmResult = await this.$confirm('您确定要删除这条信息吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).catch(err => err)

      // 如果用户取消了删除
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除！')
      }
      const { data: res } = await this.$http.delete('online/' + id, {
        headers: { Authorization: localStorage.getItem('token') }
      })
      if (res.code !== 200) {
        return this.$message.error('删除失败，请重试...')
      }
      this.$message.success('删除成功！')
      this.getMessageList()
    }
  }
}
</script>
